import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import SEO from '../components/seo'
import Layout from '../components/layout'

export const query = graphql`
  query TextPageQuery($uid: String) {
    prismic {
      content: allText_pages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            title
            content
            additional_column
          }
        }
      }
    }
  }
`

export default props => {
  const node = get(props, 'data.prismic.content.edges[0].node') || {}
  if (!node) return null
  const { title, content, additional_column } = node
  return (
    <Layout>
      {title && <SEO title={RichText.asText(title)} />}
      <div className="user-generated">
        <div className="user-generated__title">{RichText.render(title)}</div>
        {additional_column && (
          <div className="user-generated__cols">
            <div className="user-generated__col">
              {RichText.render(content)}
            </div>
            <div className="user-generated__col">
              {RichText.render(additional_column)}
            </div>
          </div>
        )}
        {!additional_column && RichText.render(content)}
      </div>
    </Layout>
  )
}
